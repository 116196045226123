<template>
  <v-dialog v-model="show" max-width="900px">
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <v-card elevation="10">
      <v-card-actions class="closeIcon"><v-icon @click="closeDialog" color="green">mdi-close</v-icon></v-card-actions>
      <v-card-title>Dodavanje oglasa za korisnika {{ `${this.productOwner.firstName} ${this.productOwner.lastName}` }}</v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation> 
        <v-row>
        <v-col cols="12" lg="6">
          <v-autocomplete
            v-model="product.choosenCategory"
            label="Kategorija"
            item-text="name"
            item-value="id"
            :items="allCategories"
            filled
            color="#137547"
            background-color="#ececef"
            :rules="categoryRules"
            @change="choosenCategoryChanged"
          ></v-autocomplete>
          <v-autocomplete
            v-model="product.choosenSubcategory"
            :label="allSubcategories.length == 0 ? 'Izaberite kategoriju prvo' : 'Podkategorija'"
            item-text="name"
            item-value="id"
            :items="allSubcategories"
            filled
            color="#137547"
            background-color="#ececef"
            :rules="subcategoryRules"
            :disabled="allSubcategories.length == 0"
          ></v-autocomplete>
          <v-text-field
            v-model.trim="product.name"
            color="#137547"
            background-color="#ececef"
            outlined
            label="Naziv oglasa"
            placeholder="Unesite naziv oglasa"
            type="text"
            class="nameField"
            :rules="nameRules"
          ></v-text-field>
              <v-text-field
                v-model.trim="product.price"
                outlined
                color="#137547"
                background-color="#ececef"
                label="Cena"
                placeholder="Unesite cenu"
                type="number"
                min="0"
                suffix="(RSD)"
                :error-messages="priceError"
                @input="changePriceFunction"
              ></v-text-field>
          <v-autocomplete
            v-model="product.otherPaymentOption"
            label="Ili umesto cene"
            item-text="name"
            item-value="id"
            :items="otherPaymentOptions"
            filled
            color="#137547"
            background-color="#ececef"
            @change="changePaymentMethodFunction"
          ></v-autocomplete>
          <v-col>
            <v-checkbox v-model="product.isPriceFixed" label="Cena je fiksna" color="#137547" :disabled="!(this.product.price > 0)"></v-checkbox>
            <v-checkbox v-model="product.isExchangePossible" label="Prihvatam zamenu" color="#137547"></v-checkbox>
          </v-col>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
            :value="`${this.productOwner.firstName} ${this.productOwner.lastName}`"
            label="Ime i prezime"
            outlined
            readonly
            color="#137547"
            background-color="#ececef"
          ></v-text-field>
          <v-checkbox v-model="product.useMyLocation" @change="useMyLocationChanger" label="Koristi korisnikovu lokaciju" color="#137547"></v-checkbox>
          <v-text-field
            :value="this.productOwner.location"
            :disabled="!product.useMyLocation"
            label="Lokacija"
            outlined
            readonly
            color="#137547"
            background-color="#ececef"
          ></v-text-field>
          <v-text-field
            v-model="product.location"
            :disabled="product.useMyLocation"
            label="Koristi drugu lokaciju"
            outlined
            color="#137547"
            background-color="#ececef"
            class="productField"
            :error-messages="otherLocationErrors"
          ></v-text-field>
          <v-checkbox v-model="product.useMyPhoneNum" @change="useMyPhoneNumChanger" label="Koristi korisnikov broj telefona" color="#137547"></v-checkbox>
          <v-text-field :value="this.productOwner.phoneNumber" :disabled="!product.useMyPhoneNum" label="Broj telefona" outlined readonly color="#137547" background-color="#ececef"></v-text-field>
          <v-text-field
            v-model="product.phoneNumber"
            :disabled="product.useMyPhoneNum"
            label="Koristi drugi broj telefona"
            outlined
            color="#137547"
            background-color="#ececef"
            :error-messages="otherPhoneNumErrors"
          ></v-text-field>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-textarea
            v-model="product.description"
            label="Tekst oglasa"
            auto-grow
            outlined
            counter="1000"
            :rules="descriptionRules"
            color="#137547"
            background-color="#ececef"
          ></v-textarea>
          </v-col>
          <v-col cols="12" lg="6">
            <v-file-input
          v-model="product.mainImage"
          accept="image/*"
          label="Izaberite glavnu sliku"
          prepend-icon="mdi-camera"
          color="#137547"
          background-color=""
          chips
          show-size
          :error-messages="mainImageErrorMessage"
          @change="mainImageChanged"
          @click:clear="clearMainImage"
          ></v-file-input>
         <div class="preview">
         <img v-if="url.mainImage" :src="url.mainImage" />
         </div>
         <v-file-input
         v-model="product.otherImages"
         accept="image/*"
         label="Izaberite ostale slike (opcionalno)"
         prepend-icon="mdi-camera"
         color="#137547"
         background-color=""
         chips
         counter
         props:
         max="15"
         multiple
         show-size
         :error-messages="otherImagesErrorMessage"
         @change="otherImagesChanged"
         truncate-length="15"
         @click:clear="clearOtherImages"
         ></v-file-input>
         <v-carousel v-if="url.otherImages.length > 0">
         <v-carousel-item v-for="(image, i) in url.otherImages" :key="i" :src="image"></v-carousel-item>
         </v-carousel>
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn color="error" text class="mr-2" @click="show = false"> Odbaci </v-btn>
        <v-btn color="success" @click="submitForm"> Postavi oglas </v-btn>
      </v-card-actions>
    </v-card>
        <v-snackbar v-model="snackbar" color="red" bottom> {{this.snackbarErrorMessage}} </v-snackbar>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "AddProductForUser",
  data() {
    return {
      loading: false,
      allCategories: [],
      allSubcategories: [],
      otherPaymentOptions: [
        { id: 1, name: "Dogovor" },
        { id: 2, name: "Kontakt" },
        { id: 3, name: "Pozvati" },
        { id: 4, name: "Besplatno" },
      ],
      product: {
        choosenCategory: null,
        choosenSubcategory: null,
        name: "",
        price: "",
        otherPaymentOption: 0,
        isPriceFixed: false,
        isExchangePossible: false,
        description: "",
        useMyLocation: true,
        location: "",
        useMyPhoneNum: true,
        phoneNumber: "",
        mainImage: null,
        otherImages: [],
      },
      url: {
        mainImage: null,
        otherImages: [],
      },
      snackbar: false,
      snackbarErrorMessage: "",
      priceError: "",
      otherLocationErrors: "",
      otherPhoneNumErrors: "",
      mainImageErrorMessage: "",
      otherImagesErrorMessage: "",
      categoryRules: [(v) => !!v || "Kategorija je obavezna."],
      subcategoryRules: [(v) => !!v || "Podategorija je obavezna."],
      nameRules: [(v) => !!v || "Ime je obavezno.", (v) => (v && v.length <= 50) || "Ime ne može da ima više od 50 karaktera."],
      descriptionRules: [(v) => v.length <= 1000 || "Opis ne može da ima više od 1000 karaktera."],
      phoneNumberRules: [(v) => !!v || "Polje ne može ostati prazno.", (v) => /^[0-9]+$/.test(v) || "Dozvoljeno je uneti samo brojeve, molimo izbrišite ostale karaktere."],
    };
  },
  methods: {
    closeDialog() {
      this.show = false;
    },
    checkPriceValidity() {
      if (!(this.product.price > 0) && !(this.product.otherPaymentOption > 0)) {
        this.priceError = "Cena mora biti navedena.";
        return false;
      } else {
        this.priceError = "";
        return true;
      }
    },
    checkOtherInfoValidity() {
      if (!this.product.useMyLocation && !(this.product.location.length > 0)) {
        this.otherLocationErrors = "Polje ne sme ostati prazno."
        return false
      } else if (this.product.location.length > 0 && (!(/^[a-zA-Z]+$/.test(this.product.location.replace(/ /g, ""))))) {
         this.otherLocationErrors = "Dozvoljena su samo slova u nazivu mesta."
        return false
      }
      else {
        this.otherLocationErrors = ""
      }

      if (!this.product.useMyPhoneNum && !(this.product.phoneNumber.length > 0)) {
        this.otherPhoneNumErrors = "Polje ne sme ostati prazno."
        return false
      } else if ((this.product.phoneNumber.length > 0 && !(/^[+\d](?:.*\d)?$/.test(this.product.phoneNumber)))) {
         this.otherPhoneNumErrors = "Dozvoljen je unos samo brojeva."
        return false
      } else {
        this.otherPhoneNumErrors = ""
      }
      return true
    },
    async submitForm() {

      if (this.$refs.form.validate() && this.checkPriceValidity() && this.checkOtherInfoValidity()) {
      this.loading = true;
      const postUrl = `/Admin/PublishProductForUser/${this.productOwner.id}`
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const FormData = require("form-data");
      let formData = new FormData();

      formData.append("Title", this.product.name);
      formData.append("Description", this.product.description);
      formData.append("SubCategoryId", this.product.choosenSubcategory);
      formData.append("IsPriceFixed", this.product.isPriceFixed);
      formData.append("PaymentOption", this.product.otherPaymentOption);
      formData.append("IsExchangePossible", this.product.isExchangePossible);
      if (this.product.otherPaymentOption > 0) {
        formData.append("Price", 0);
      } else {
        formData.append("Price", this.product.price);
      }
      if (this.product.mainImage != null) {
        formData.append("MainImgFile", this.product.mainImage);
      }
      if (this.product.otherImages?.length > 0) {
        formData.append("SecondaryImgFiles", this.product.otherImages);
      }
      if (this.product.useMyPhoneNum) {
        formData.append("PhoneNumber", this.productOwner.phoneNumber);
      } else {
        formData.append("PhoneNumber", this.product.phoneNumber.trim());
      }
      if (this.product.useMyLocation) {
        formData.append("Location", this.productOwner.location);
      } else {
        formData.append("Location", this.product.location.trim());
      }

        await axios
          .post(postUrl, formData, config)
          .then(() => {
          this.$emit("productIsAdded");
          let initialValues = {
            choosenCategory: null,
            choosenSubcategory: null,
            name: "",
            price: "",
            otherPaymentOption: 0,
            isPriceFixed: false,
            isExchangePossible: false,
            description: "",
            useMyLocation: true,
            location: "",
            useMyPhoneNum: true,
            phoneNumber: "",
            mainImage: null,
            otherImages: [],
     }
          let initialImages = {
             mainImage: null,
             otherImages: [],
          }
            this.product = initialValues
            this.url = initialImages
            this.loading = false;
            this.show = false;
          })
          .catch((error) => {
          if (error.response.data == "Already has the same product listed.") {
            this.loading = false;
            this.snackbarErrorMessage = "Već postoji korisnikov oglas sa ovim nazivom.";
            this.snackbar = true
            setTimeout(() => {
              this.snackbar = false
              this.snackbarErrorMessage = ""
            }, 3000);
          } else {
            this.loading = false;
            this.snackbarErrorMessage = "Došlo je do greške. Pokušajte ponovo kasnije.";
            this.snackbar = true
            setTimeout(() => {
              this.snackbar = false
              this.snackbarErrorMessage = ""
            }, 3000);
          }
          });
      }
    },
    getCategories() {
      axios.get("Category").then((response) => {
        let tempCat = response.data.map((item) => {
          return {
            ...item,
          };
        });
        this.allCategories = tempCat;
        if (this.product?.category > 0) {
          let tempSubCat = tempCat.find((x) => x.id === this.product.category);
          this.allSubcategories = tempSubCat.subCategories;
        }
      });
    },
    getSubcategories(categoryId) {
      let tempObject = this.allCategories.find((x) => x.id === categoryId);
      this.allSubcategories = tempObject.subCategories;
    },
    choosenCategoryChanged() {
      this.product.choosenSubcategory = 0;
      this.getSubcategories(this.product.choosenCategory);
    },
    changePriceFunction() {
      this.product.price = this.product.price.replace(/^0+/, "");
      if (this.product.price > 0) {
        this.product.otherPaymentOption = 0;
        this.checkPriceValidity();
      }
    },
    changePaymentMethodFunction() {
      if (this.product?.otherPaymentOption > 0) {
        this.product.price = "";
        this.product.isPriceFixed = false;
        this.checkPriceValidity();
      }
    },
    useMyLocationChanger() {
      this.product.location = "";
    },
    useMyPhoneNumChanger() {
      this.product.phoneNumber = "";
    },
        mainImageChanged(e) {
      if (e === null) {
        return;
      }
      if (e?.type !== "image/jpg" && e?.type !== "image/jpeg" && e?.type !== "image/png") {
        this.mainImageErrorMessage = "Dozvoljeni su samo jpg, jpeg i png formati slike.";
        this.mainImage = null;
      } else if (e.size > 3000000) {
        this.mainImageErrorMessage = "Maksimalna velicina slike je 3 MB!";
        this.mainImage = null;
      } else {
        this.mainImage = e;
        this.url.mainImage = URL.createObjectURL(e);
      }
    },
    otherImagesChanged(e) {
      let temp = [];
      if (e?.length > 15) {
        this.otherImagesErrorMessage = "Maksimalan broj slika koje možete dodati je 10.";
      }
      for (let i = 0; i < e.length; i++) {
        if (e[i]?.type !== "image/jpg" && e[i]?.type !== "image/jpeg" && e[i]?.type !== "image/png") {
          this.otherImagesErrorMessage = "Dozvoljeni su samo jpg, jpeg i png formati slike.";
        } else if (e.size > 3000000) {
          this.otherImagesErrorMessage = "Maksimalna velicina slike je 3 MB!";
        } else {
          let tempObject = URL.createObjectURL(e[i]);
          temp.push(tempObject);
        }
      }
      this.url.otherImages = temp;
    },
    clearMainImage() {
      this.mainImageErrorMessage = "";
      this.mainImage = null;
      this.url.mainImage = null;
    },
    clearOtherImages() {
      this.otherImagesErrorMessage = "";
      this.otherImages = [];
      this.url.otherImages = [];
    },
  },
  props: {
    value: Boolean,
    user: {},
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    productOwner: {
      get() {
        return this.user;
      },
      set() {
        //
      },
    },
  },
  created() {
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
.closeIcon {
  display: flex;
  justify-content: flex-end;
}

.forgottenPassword {
  color: #137547;
}
.noAccountQuestion {
  border-top: 2px solid #137547;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.rounded-card {
  border-radius: 50px;
}

p {
  color: red;
  text-align: center;
}
</style>
