<template>
  <div v-if="isLoggedIn" class="main">
    <NavBar />
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <v-data-table :headers="headers" :items="allUsers" :key="allUsers.id" class="usersTable">
      <template v-slot:item.mainImgName="{ item }">
        <v-avatar rounded>
          <img
            :src="'https://api.poljopijaca.rs/' + item.mainImgName ? 'https://api.poljopijaca.rs/' + item.mainImgName : 'https://api.poljopijaca.rs/default-avatar.png'"
            style="width: 50px; height: 50px"
          />
        </v-avatar>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Korisnici</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" dark class="topBtn" @click="showAddUsers = true"> Dodaj korisnika </v-btn>
        </v-toolbar>
        <AddUserTable v-model="showAddUsers" @userAdded="liveUpdate" />
        <v-dialog v-model="editDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field color="#137547" dense outlined v-model="editedItem.firstName" label="Ime"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field color="#137547" dense outlined v-model="editedItem.lastName" label="Prezime"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field color="#137547" dense outlined v-model="editedItem.userName" label="Korisničko ime"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field color="#137547" dense outlined v-model="editedItem.email" label="Email"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text class="mr-2" @click="close"> Odbaci </v-btn>
              <v-btn color="success" @click="updateUser"> Sačuvaj </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        <v-icon small @click="showAddProductDialog(item)"> mdi-plus </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" color="green" bottom> {{ this.snackbarMessage }}</v-snackbar>
    <v-snackbar v-model="errorSnackbar" color="red" bottom> Došlo je do greške. </v-snackbar>
    <DeleteUser v-model="showDeleteDialog" :user="userForDeletion" @userIsDeleted="userDeleted" />
    <AddProductForUser v-model="addProductDialog" :user="productOwner" @productIsAdded="productIsAdded" />
  </div>
</template>

<script>
import axios from "axios";
import AddUserTable from "../AddUser/AddUser.vue";
import AddProductForUser from "./AddProductForUser.vue";
import DeleteUser from "./DeleteUser.vue";
import NavBar from "../../../Components/NavBar.vue";

export default {
  components: {
    NavBar,
    AddProductForUser,
    AddUserTable,
    DeleteUser,
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarMessage: "",
      errorSnackbar: false,
      editDialog: false,
      showDeleteDialog: false,
      userForDeletion: {},
      showAddUsers: false,
      addProductDialog: false,
      productOwner: {},
      headers: [
        { text: "Ime", align: "start", value: "firstName" },
        { text: "Prezime", align: "start", value: "lastName" },
        { text: "Korisničko ime", value: "userName" },
        { text: "Email", value: "email" },
        { text: "Uredi", value: "actions", sortable: false },
      ],
      mainImage: null,
      url: {
        mainImage: null,
      },
      allUsers: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        mainImgName: "",
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        location: "",
        phoneNumber: "",
      },
      defaultItem: {
        id: "",
        mainImgName: "",
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        location: "",
        phoneNumber: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Dodaj korisnika" : "Uredi";
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
  },

  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.loading = true;
      const users = await axios.get("User").catch(() => {
        this.loading = false;
      });

      const usersObject = users.data?.map((el) => {
        return {
          ...el,
        };
      });
      this.allUsers = usersObject;
      this.loading = false;
    },
    liveUpdate() {
      this.getUsers().then(() => {
        this.snackbar = true;
        this.snackbarMessage = "Korisnik je uspešno dodat";

        setTimeout(() => {
          this.snackbar = false;
          this.snackbarMessage = "";
        }, 3000);
      });
    },

    async userDeleted() {
      this.getUsers().then(() => {
        this.snackbar = true;
        this.snackbarMessage = "Korisnik je uspešno obrisan";

        setTimeout(() => {
          this.snackbar = false;
          this.snackbarMessage = "";
        }, 3000);
      });
    },

    async updateUser() {
      await axios.put(`User/${this.editedItem.id}`, this.editedItem).then(() => {
        this.getUsers();
        this.editDialog = false;

        this.snackbarMessage = "Korisnik je uspešno izmenjen.";
        this.snackbar = true;

        setTimeout(() => {
          this.snackbar = false;
          this.snackbarMessage = "";
        }, 3000);
      });
    },
    uploadFile(e) {
      this.mainImage = e;
      this.url.mainImage = URL.createObjectURL(e);
    },
    async addUser() {
      const formData = new FormData();
      formData.append("FirstName", this.editedItem.firstName);
      formData.append("LastName", this.editedItem.lastName);
      formData.append("UserName", this.editedItem.userName);
      formData.append("IsActive", 1);
      formData.append("Email", this.editedItem.email);
      formData.append("Type", 3);
      formData.append("MainImgFile", this.mainImage);
      await axios.post("User", formData, { headers: { "Content-Type": "multipart/form-data" } });
      this.getUsers();
    },

    deleteItem(item) {
      this.userForDeletion = item;
      this.showDeleteDialog = true;
    },

    editItem(item) {
      this.editedIndex = this.allUsers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
    },
    showAddProductDialog(item) {
      this.productOwner = item;
      this.addProductDialog = true;
    },
    close() {
      (this.editDialog = false), this.editedItem.firstName, (this.mainImage = null), (this.url.mainImage = null), this.editedItem.lastName, this.editedItem.email;
    },
    productIsAdded() {
      this.snackbar = true;
      this.snackbarMessage = `Uspešno je dodat oglas za korisnika ${this.productOwner.firstName} ${this.productOwner.lastName}`;

      setTimeout(() => {
        this.snackbar = false;
        this.snackbarMessage = "";
      }, 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
.searchUser {
  margin: 15px auto;
  margin-left: 15px;
  .searchField {
    margin: auto;
    width: 30%;
    display: inline-block;
  }
  .topBtn {
    display: inline-block;
    margin-left: 15px;
  }
}
.addUser {
  margin: 15px auto;

  margin-left: 15px;
  .topBtn {
    display: inline-block;
    width: auto;
  }
}
.main {
  margin-bottom: 85px;
}
.usersTable {
  width: 1440px;
  margin: 2rem auto;
}
.avatar {
  justify-content: center;
  align-items: center;
}
</style>
